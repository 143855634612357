/*  React Tinongo <AppShell> component */

import React, { Component } from 'react';
import { UIContext, Viewport } from '../context/ui';

import Appbar from './app-bar';
import BottomSideBar from './bottom-side-bar';
import Footer from './footer';
import Main from './main';
import Navigation from './navigation';
import NavigationDrawer from './navigation-drawer';

/* <AppShell> component */
class AppShell extends Component {
  /**
   * Constructor:
   * Initializes state and binds methods.
   */
  constructor(props) {
    super(props);

    this.state = {
      navDrawerIsOpen: false,
      openNavDrawer: this.openNavDrawer,
      closeNavDrawer: this.closeNavDrawer,

      actionDrawerIsOpen: false,
      openActionDrawer: this.openActionDrawer,
      closeActionDrawer: this.closeActionDrawer,

      layout: undefined,
    };

    this.updateLayout = this.updateLayout.bind(this);
    this.bodyScroll = this.bodyScroll.bind(this);
    this.bodyNoScroll = this.bodyNoScroll.bind(this);
    this.openNavDrawer = this.openNavDrawer.bind(this);
    this.closeNavDrawer = this.closeNavDrawer.bind(this);
  }

  /**
   * Lifecycle hook:
   * Runs after the component output has been rendered to the DOM.
   */
  componentDidMount() {
    this.updateLayout();
    this.bodyScroll();
    window.addEventListener('resize', this.updateLayout);
  }

  /**
   * Lifecycle hook:
   * Called if the component is removed from the DOM.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  /**
   * Updates the layout in order to adapt to different screen/viewport sizes.
   */
  updateLayout() {
    if (Viewport.isMedium()) {
      this.closeNavDrawer();
      this.closeActionDrawer();
    }

    if (Viewport.isXsmall() || Viewport.isSmall()) {
      this.setState({ layout: 'mobile' });
    } else {
      this.setState({ layout: 'desktop' });
    }
  }

  /**
   * Sets <body> element style to overflow: auto
   */
  bodyScroll() {
    document.body.style.overflow = 'auto';
  }

  /**
   * Sets <body> element style to overflow: hidden
   */
  bodyNoScroll() {
    document.body.style.overflow = 'hidden';
  }

  /**
   * Opens the navigation drawer.
   */
  openNavDrawer() {
    this.setState({ navDrawerIsOpen: true });
    this.bodyNoScroll();
  }

  /**
   * Closes the navigation drawer.
   */
  closeNavDrawer() {
    this.setState({ navDrawerIsOpen: false });
    this.bodyScroll();
  }

  /**
   * Opens the action drawer.
   */
  openActionDrawer = () => {
    this.setState({ actionDrawerIsOpen: true });
    this.bodyNoScroll();
  };

  /**
   * Closes the action drawer.
   */
  closeActionDrawer = () => {
    this.setState({ actionDrawerIsOpen: false });
    this.bodyScroll();
  };

  render() {
    const navItems = [
      { href: '/worum-geht-es-hier', text: 'Worum geht es hier' },
      { href: '/botschafter', text: 'Botschafter' },
      { href: '/kiss', text: 'KiSS' },
      { href: '/mitmachen', text: 'Mitmachen' },
      { href: '/ueber-uns', text: 'Über uns' },
      { href: '/faq', text: 'FAQ' },
    ];

    return (
      <UIContext.Provider value={this.state}>
        <Appbar menuButtonClick={this.openNavDrawer}>
          <Navigation items={navItems} showInAppBar />
        </Appbar>
        <BottomSideBar />
        <NavigationDrawer
          isOpen={this.state.navDrawerIsOpen}
          closeDrawer={this.closeNavDrawer}>
          <Navigation
            items={navItems}
            onClick={this.closeNavDrawer}
            showInDrawer
          />
        </NavigationDrawer>
        <Main>{this.props.children}</Main>
        <Footer />
      </UIContext.Provider>
    );
  }
}

export default AppShell;
