/* React Tinongo <BottomSideBar> component */

import React from 'react';
import { Link } from 'gatsby';

import styles from './bottom-side-bar.module.scss';

/* __<Action> component__ */
function Action(props) {
  return props.external ? (
    <a
      href={props.href}
      target={props.blank ? '_blank' : '_self'}
      rel="noopener noreferrer"
      className={styles.bottomSideBarActionLink}>
      <i className={`material-icons`}>{props.materialIcon}</i>
      <span>{props.label}</span>
    </a>
  ) : (
    <Link
      to={props.href}
      className={styles.bottomSideBarActionLink}
      activeClassName={styles.bottomSideBarActionLinkIsActive}>
      <i className={`material-icons`}>{props.materialIcon}</i>
      <span>{props.label}</span>
    </Link>
  );
}

/* <BottomSideBar> component */
function BottomSideBar() {
  return (
    <aside className={styles.bottomSideBar}>
      <div className={styles.bottomSideBarInner}>
        <Action
          href="/suche/sportarten"
          materialIcon="search"
          label="Sportarten"
        />
        <Action href="/suche/vereine" materialIcon="place" label="Vereine" />
        <Action
          href="https://blog.tinongo.org"
          materialIcon="notes"
          label="Blog"
          external
          blank
        />
      </div>
    </aside>
  );
}

export default BottomSideBar;
