import React from 'react';

/* Viewport related utils functions */
export class Viewport {
  static mediaQueries = {
    xsmall: 'screen and (min-width: 0px) and (max-width: 600px)',
    small: 'screen and (min-width: 601px) and (max-width: 960px)',
    smallLandscape:
      'screen and (max-width: 960px) and (orientation: landscape)',
    medium: 'screen and (min-width: 961px) and (max-width: 1280px)',
    large: 'screen and (min-width: 1281px) and (max-width: 1559px)',
    xlarge: 'screen and (min-width: 1560px)',
  };

  static isXsmall() {
    return window.matchMedia(this.mediaQueries.xsmall).matches;
  }

  static isSmall() {
    return window.matchMedia(this.mediaQueries.small).matches;
  }

  static isSmallLandscape() {
    return window.matchMedia(this.mediaQueries.smallLandscape).matches;
  }

  static isMedium() {
    return window.matchMedia(this.mediaQueries.medium).matches;
  }

  static isLarge() {
    return window.matchMedia(this.mediaQueries.large).matches;
  }

  static isXlage() {
    return window.matchMedia(this.mediaQueries.xlarge).matches;
  }
}

/* Context object for UI */
export const UIContext = React.createContext();
