/*  React Tinongo <Appbar> component */

import React from 'react';
import { navigate } from 'gatsby';

import Button from './button';
import styles from './app-bar.module.scss';
import logo from '../images/logo.svg';

/* <Appbar> component */
function Appbar(props) {
  return (
    <header className={styles.appBar}>
      <div
        className={styles.appBarContentLeft}
        role="button"
        onClick={() => {
          navigate('/');
        }}
        onKeyPress={() => {
          navigate('/');
        }}
        tabIndex="0">
        <img className={styles.appBarLogo} src={logo} alt="Tinongo" />
      </div>
      <div className={styles.appBarContentRight}>
        {props.children}
        <div className={styles.appBarMenuButton}>
          <Button
            flat
            icon
            aria-label="Menu"
            onClick={props.menuButtonClick}>
            <i className="material-icons" aria-hidden="true">
              menu
            </i>
          </Button>
        </div>
      </div>
    </header>
  );
}

export default Appbar;
