/* React Tinongo <Button> component */

import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './button.module.scss';

/* The <Button> component */
function Button(props) {
  const classes = classNames({
    [`${styles.button}`]: true,
    [`${styles.buttonFlat}`]: props.flat,
    [`${styles.buttonRaised}`]: props.raised,
    [`${styles.buttonDisabled}`]: props.disabled,
    [`${styles.buttonBlock}`]: props.block,
    [`${styles.buttonIconLight}`]: props.icon && props.light,
    [`${styles.buttonIconDark}`]: props.icon && props.dark,
  });

  /**
   * Returns <a> wrapper for rendering.
   */
  const anchorElement = (classes) => {
    return (
      <Link to={props.href} className={classes} {...props}>
        <span className={styles.contentWrapper}>
          <span className={styles.content}>{props.children}</span>
        </span>
      </Link>
    );
  };

  /**
   * Returns <button> wrapper for rendering.
   */
  const buttonElement = (classes) => {
    const isDisabled = props.disabled ? true : false;

    return (
      <button className={classes} disabled={isDisabled} {...props}>
        <span className={styles.contentWrapper}>
          <span className={styles.content}>{props.children}</span>
        </span>
      </button>
    );
  };

  /**
   * Returns the <Button> React element.
   */
  return props.isLink ? anchorElement(classes) : buttonElement(classes);
}

export default Button;
