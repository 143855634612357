/* React Tinongo <Footer> component */

import React from 'react';

import Navigation from './navigation';

import styles from './footer.module.scss';

/* <Footer> component */
function Footer(props) {
  const links = [
    { href: '/impressum', text: 'Impressum' },
    { href: '/datenschutz', text: 'Datenschutz' },
    { href: '/haftungsausschluss', text: 'Haftungsausschluss' },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <span className={styles.footerCopy}>
          &copy;{new Date().getFullYear()} Tinongo
        </span>
        <div className={styles.footerLinks}>
          <Navigation items={links} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
