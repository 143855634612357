/* React Tinongo <Main> component */

import React from 'react';

import styles from './main.module.scss';

/* <Main> component */
function Main(props) {
  return <main className={styles.main}>{props.children}</main>;
}

export default Main;
