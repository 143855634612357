/* React Tinongo <Navigation> component */

import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import styles from './navigation.module.scss';

/* The <Navigation> component */
function Navigation(props) {
  const classes = classNames({
    [`${styles.navigation}`]: true,
    [`${styles.inAppBar}`]: props.showInAppBar,
    [`${styles.inDrawer}`]: props.showInDrawer,
  });

  const navItems = props.items.map((item) => {
    if (item.external) {
      // Use <a> tag for external links
      return (
        <li className={styles.navigationItem} key={item.href}>
          <a
            href={item.href}
            className={styles.navigationLink}
            target="_blank"
            rel="noopener noreferrer">
            {item.text}
          </a>
        </li>
      );
    } else {
      // Use Gatsby Link for internal links
      return (
        <li className={styles.navigationItem} key={item.href}>
          <Link
            to={item.href}
            className={styles.navigationLink}
            activeClassName={styles.navigationLinkActive}
            onClick={item.onClick}>
            <span className={styles.navigationLabel}>{item.text}</span>
          </Link>
        </li>
      );
    }
  });

  return (
    <nav className={classes}>
      <ul className={styles.navigationItems}>{navItems}</ul>
    </nav>
  );
}

export default Navigation;
