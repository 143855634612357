/* React Tinongo <Drawer> component */

import React from 'react';
import classNames from 'classnames';

import Button from './button';
import Overlay from './overlay';

import styles from './drawer.module.scss';

/* <Drawer> component */
function Drawer(props) {
  const classes = classNames({
    [`${styles.drawerIsHidden}`]: !props.isOpen,
    [`${styles.drawerIsVisible}`]: props.isOpen,
    [`${styles.enterTop}`]: props.enterTop,
    [`${styles.enterRight}`]: props.enterRight,
    [`${styles.enterBottom}`]: props.enterBottom,
    [`${styles.enterLeft}`]: props.enterLeft,
    [`${styles.fullScreen}`]: props.fullScreen,
  });

  return (
    <>
      <aside className={classes}>
        <div className={styles.closeDrawer}>
          <Button
            flat="true"
            icon="true"
            light="true"
            aria-label="Close"
            onClick={props.closeDrawer}>
            <i className="material-icons" aria-hidden="true">
              close
            </i>
          </Button>
        </div>
        <div className={styles.drawerInner}>{props.children}</div>
      </aside>
      {!props.fullScreen && (
        <Overlay isVisible={props.isOpen} onClick={props.closeDrawer} />
      )}
    </>
  );
}

export default Drawer;
