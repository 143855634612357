/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ lang, locale, title, description, image, pathname }) {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultLanguage,
            defaultLocale,
            defaultTitle,
            siteTitle,
            titleTemplate,
            defaultDescription,
            siteUrl,
            defaultImage,
            twitterUsername,
          },
        },
      }) => {
        const seo = {
          lang: lang || defaultLanguage,
          locale: locale || defaultLocale,
          title: title || defaultTitle,
          siteTitle: siteTitle,
          description: description || defaultDescription,
          image: `${image || siteUrl + defaultImage}`,
          url: `${siteUrl}${pathname || '/'}`,
        };

        return (
          <>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
              <html lang={seo.lang} />
              <meta name="description" content={seo.description} />

              {/* Essential META tags */}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && <meta property="og:image" content={seo.image} />}
              {seo.url && <meta property="og:url" content={seo.url} />}
              {seo.title && <meta name="twitter:card" content={seo.title} />}

              {/* Recommended META tags */}
              {seo.siteTitle && (
                <meta property="og:site_name" content={seo.siteTitle} />
              )}
              {seo.title && (
                <meta name="twitter:image:alt" content={`Foto ${seo.title}`} />
              )}
              {seo.locale && <meta property="og:locale" content={seo.locale} />}
              <meta property="og:type" content="website" />

              {/* Special META tags */}
              {twitterUsername && (
                <meta name="twitter:site" content={twitterUsername} />
              )}
              <meta name="twitter:dnt" content="on" />
            </Helmet>
          </>
        );
      }}
    />
  );
}

export default SEO;

SEO.propTypes = {
  lang: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  lang: null,
  locale: null,
  title: null,
  description: null,
  image: null,
  pathname: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultLanguage: lang
        defaultLocale: locale
        defaultTitle: title
        siteTitle
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
