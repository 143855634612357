/* React Tinongo <Overlay> component */

import React from 'react';
import classNames from 'classnames';

import styles from './overlay.module.scss';

/* <Overlay> component */
function Overlay(props) {
  const classes = classNames({
    [`${styles.overlay}`]: true,
    [`${styles.overlayIsVisible}`]: props.isVisible,
  });

  return (
    <div className={classes} onClick={props.onClick} role="presentation"></div>
  );
}

export default Overlay;
