/* React Tinongo <NavigationDrawer> component */

import React from 'react';

import Drawer from './drawer';
import Navigation from './navigation';

import styles from './navigation-drawer.module.scss';

/* <NavigationDrawer> component */
function NavigationDrawer(props) {
  const footerItems = [
    { href: '/impressum', text: 'Impressum' },
    { href: '/datenschutz', text: 'Datenschutz' },
    { href: '/haftungsausschluss', text: 'Haftungsausschluss' },
  ];

  return (
    <Drawer enterRight isOpen={props.isOpen} closeDrawer={props.closeDrawer}>
      <div className={styles.navigationDrawer}>
        {props.children}

        <footer className={styles.navigationDrawerFooter}>
          <div className={styles.navigationDrawerFooterItems}>
            <span className={styles.navigationDrawerFooterCopy}>
              &copy;{new Date().getFullYear()} Tinongo
            </span>
            <div className={styles.navigationDrawerFooterLinks}>
              <Navigation items={footerItems} onClick={props.onClick} />
            </div>
          </div>
        </footer>
      </div>
    </Drawer>
  );
}

export default NavigationDrawer;
